import React from "react";
import { useNavigate } from "react-router-dom";

const FeaturedArticleCard = (props) => {
  const { title, image_url, id } = props.article;
  const navigate = useNavigate();

  // Function to handle card click
  const handleCardClick = () => {
    navigate(`/article/${id}`);
  };

  return (
    <div
      className="card col-lg-2 col-md-2 col-sm-3 mb-3 position-relative"
      onClick={handleCardClick}
      style={{ height: "300px" }} // Set a fixed height for consistent appearance
    >
      <div style={{ position: "relative", height: "100%" }}>
        <img
          className="card-img-top img-fluid"
          src={image_url}
          alt="Article"
          style={{
            objectFit: "cover", // Ensure the image fills the container while maintaining aspect ratio
            maxWidth: "100%",
            minWidth: "20vw",
            height: "100%", // Ensure the image takes up the full height of the container
          }}
        />
        <div
          className="card-body"
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0, 0, 0, 0.7)",
            padding: "20px", // Increase the padding to cover a larger portion of the image
          }}
        >
          <h5 className="card-title" style={{ color: "white" }}>
            {title}
          </h5>
        </div>
      </div>
    </div>
  );
};

export default FeaturedArticleCard;
