// import { ApplicationInsights } from "@microsoft/applicationinsights-web";
// import logo from "./perceptrondev-high-resolution-logo-transparent.png";

import "./App.css";
import BlogForm from "./layout/BlogForm";
import React, { useEffect, useState } from "react";
import "./App.css";
import { PageLayout } from "./components/PageLayout";
import Home from "./layout/Home.js";
import About from "./layout/About.js";
import Contact from "./layout/Contact.js";

import Article from "./layout/Article.js";
// import { b2cPolicies, protectedResources } from "./authConfig";
import { compareIssuingPolicy } from "./utils/claimUtils";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import axios from "axios";

import { msalConfig, loginRequest } from "./authConfig.js";
import { callMsGraph } from "./graph";
import { ProfileData } from "./components/ProfileData";

import Burger from "./layout/Burger.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import LoginRedirect from "./layout/LoginRedirect.jsx";
import Videos from "./layout/Videos.js";

import { PublicClientApplication } from "@azure/msal-browser";

const msalInstance = new PublicClientApplication(msalConfig);

const initializeMsal = async () => {
  try {
    // This line should be removed as PublicClientApplication does not have an initialize method.
    await msalInstance.initialize(msalConfig);
  } catch (error) {
    console.error("Error initializing MSAL:", error);
  }
};

export { msalInstance, initializeMsal };

const Pages = () => {
  const { accounts, instance, inProgress } = useMsal();
  const [graphData, setGraphData] = useState(null);

  useEffect(() => {
    const initializeMsalIfNeeded = async () => {
      if (!inProgress && !accounts.length && instance) {
        try {
          await msalInstance.initialize(msalConfig);
        } catch (error) {
          console.error("Error initializing MSAL:", error);
        }
      }
    };

    initializeMsalIfNeeded();
  }, [instance, inProgress, accounts]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userFromAuthLibrary = instance.getActiveAccount();
        const payload = JSON.stringify({
          homeAccountId: userFromAuthLibrary.homeAccountId,
          environment: userFromAuthLibrary.environment,
          tenantId: userFromAuthLibrary.tenantId,
          localAccountId: userFromAuthLibrary.localAccountId,
          username: userFromAuthLibrary.username,
          name: userFromAuthLibrary.name,
          authorityType: userFromAuthLibrary.authorityType,
          tenantProfiles: userFromAuthLibrary.tenantProfiles,
          idTokenClaims: userFromAuthLibrary.idTokenClaims,
        });

        await axios.post(
          "https://percms.ukwest.cloudapp.azure.com/users",
          payload
        );
        console.log("User data sent to the server successfully");
      } catch (error) {
        console.error("Error sending user data to the server:", error);
      }
    };

    const requestProfileData = async () => {
      try {
        // Ensure MSAL is not in progress and an account is available
        if (!inProgress && accounts.length > 0) {
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });

          const graphResponse = await callMsGraph(response.accessToken);
          setGraphData(graphResponse);
        }
      } catch (error) {
        console.error("Error requesting profile data:", error);
      }
    };

    fetchData();
    requestProfileData();
  }, [instance, inProgress, accounts]);

  return (
    <div>
      <div className="container">
        <Burger />{" "}
        <Routes>
          {/* A <Switch> loohjks through its children <Route>s and
            renders the first one that matches the current URL. */}

          <Route path="/createarticle" element={<BlogForm />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/blogform" element={<BlogForm />}></Route>
          <Route
            path="/article/:articleId"
            element={<Article fetchDataFromAPI={true} />}
          ></Route>

          {/* <Route path="/article" element={<BlogForm />}></Route> */}

          <Route exact path="/videos" element={<Videos />}></Route>
          <Route exact path="/" element={<Home />}></Route>

          {/* <PrivateRoute path="/signout" component={SignOut}>
                <SignOut />
              </PrivateRoute>
              <PrivateRoute path="/profile" component={Profile} />
              <Route>
                <Route path="/auth/:authType/:id?" component={AuthPage} />
              </Route>

              <Route>
                <Route path="/auth/register" component={AuthPage} />
              </Route> */}
          <Route
            exact
            path="/connect/:providerName/redirect"
            component={LoginRedirect}
          />
        </Routes>
        {/* <PrivateRoute exact path="/product" component={ProductPage} />
          <PrivateRoute path="/:contentType/:id" component={EditPage} /> */}
        {/* <Route exact path="/connect/:provider" component={ConnectPage} />
              <Route path="" component={NotFoundPage} /> */}
      </div>
    </div>
  );
};

const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <PageLayout>
        <Pages />
        {/* </AuthenticatedTemplate> */}
      </PageLayout>
    </MsalProvider>
  );
};
export default App;
