import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "./Carousel";
import MyCard from "./MyCard.jsx";
import MainArticle from "./MainArticle.jsx";
import axios from "axios";
import percep from "../Mark_I_perceptron.jpeg";
import styled from "styled-components";
import { Link } from "react-router-dom";
import locArticles from "../data/articles.json";
import FeaturedArticleCard from "./FeaturedArticleCard.jsx";
const P = styled.p``;
const H1 = styled.h1`
  color: white;
  font-size: smaller;
  margin: 0 auto;
  text-align: left;
`;

const Home = () => {
  const [articles, setArticles] = useState(locArticles);
  const navigate = useNavigate();
  const [mainArticle, setMainArticle] = useState(locArticles[0]);
  const getData = async () => {
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
    try {
      await axios
        .get("https://percms.ukwest.cloudapp.azure.com/BlogArticles", {
          crossdomain: true,
        })
        .then((data) => setArticles(data.data));
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const handleClick = (id) => {
    navigate(`/article/${id}`);
  };

  return (
    <div className="justify-content-center ">
      {/* ... Your existing code ... */}
      <div className="col-12">
        <div className="row">
          <div className="col-12">
            <div
              className="d-none d-md-flex flex-row"
              style={{ overflowX: "auto" }}
            >
              {articles.map((article) => (
                <FeaturedArticleCard
                  key={article.id}
                  article={article}
                  onClick={handleClick}
                />
              ))}
            </div>
            <div className="d-md-none d-flex flex-column">
              {/* Show only a couple of FeaturedArticleCards on mobile */}
              {articles.slice(0, 2).map((article) => (
                <FeaturedArticleCard
                  key={article.id}
                  article={article}
                  onClick={handleClick}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="row  justify-content-center  pt-3">
        <div className="col-lg-3 col-md-3 col-sm-12">
          <div className="card" as={Link} to={"/contact"}>
            <img
              className="card-img-top imageCard"
              src={percep}
              alt="perceptron logo icon"
            />
            <div className="card-body">
              <h5 className="card-title">Perceptron OG</h5>

              <p className="regtext">
                The perceptron was invented in 1943 by Warren McCulloch and
                Walter Pitts.[5] The first hardware implementation was Mark I
                Perceptron machine built in 1957 at the Cornell Aeronautical
                Laboratory by Frank Rosenblatt,[6] funded by the Information
                Systems Branch of the United States Office of Naval Research and
                the Rome Air Development Center. It was first publicly
                demonstrated on 23 June 1960.[7] The machine was "part of a
                previously secret four-year NPIC [the US' National Photographic
                Interpretation Center] effort from 1963 through 1966 to develop
                this algorithm into a useful tool for photo-interpreters".[8]
              </p>
            </div>{" "}
          </div>{" "}
        </div>{" "}
        <div className="col-lg-6 col-md-6 col-sm-12  imageCard">
          {/* <MainArticle article={mainArticle} />*/}
          <iframe
            width="100%"
            height="50%"
            src="https://www.youtube.com/embed/vjEm3vPdaoY?si=i3uBBajA-_L-Egqz"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <blockquote class="blockquote text-center ">
            <p class="mb-3 mt-5"> perceptron.dev is calling</p>
            <footer class="blockquote-footer">
              Constantin Dimitrenco{" "}
              <cite title="Source Title">Perceptron founder</cite>
            </footer>
          </blockquote>
        </div>{" "}
        <div className="col-lg-3 col-md-3 col-sm-12 p-12 scroll">
          {articles.map((article) => {
            return <MyCard article={article} onClick={handleClick} />;
          })}
        </div>
      </div>{" "}
    </div>
  );
};

export default Home;
