import React from "react";
import girl from "../img/out-0 (4).png";
import { Image } from "react-bootstrap";
const Contact = () => {
  return (
    <div className="row justify-content-center mt-5">
      <div className="col-lg-5 col-sm-12">
        {" "}
        <Image
          fluid
          src={girl}
          alt="atomic brain"
          style={{ justifySelf: "center" }}
        />
      </div>
      <div className="col-lg-5 col-sm-12">
        <p
          className="hover"
          style={{
            zIndex: 3,
            color: "grey",
            marginTop: "30%",
            fontWeight: 400,
            textAlign: "center",
            fontFamily: "Arial",
          }}
        >
          <strong> Constantin Dimitrenco, </strong>
          <br /> CEO Perceptron.dev:
          <br />
          constantin@perceptron.dev
        </p>
        <p
          className="hover"
          style={{
            zIndex: 3,
            color: "black",

            fontWeight: 100,

            textAlign: "center",
            fontFamily: "Arial",
          }}
        ></p>
      </div>
    </div>
  );
};

export default Contact;
