import React, { useEffect, useState } from "react";
import useInterval from "use-interval";
import girl from "../img/out-0 (5).png";
import boy from "../img/out-0.png";
import rammstein from "../img/out-0 (1).png";
// import axios from "axios";

function Carousel() {
  const [greatIndex, setGreatIndex] = useState(0);

  const [imageArray, setImageArray] = useState([
    { image: boy },
    { image: girl },
    { image: rammstein },
  ]);

  const handleForward = () => {
    console.log(greatIndex);
    if (greatIndex < imageArray.length - 1) {
      let newIndex = greatIndex + 1;

      setGreatIndex(newIndex);
    } else {
      setGreatIndex(0);
    }
  };
  useInterval(() => {
    // Your custom logic here
    handleForward();
  }, 5000); // passing null instead of 1000 will cancel the interval if it is already running

  // const handleBackward = () => {
  //   if (greatIndex > 0) {
  //     setGreatIndex(greatIndex - 1);
  //   } else {
  //     setGreatIndex(imageArray.length - 1);
  //   }
  // };

  return (
    <div
      className="justify-content-center"
      style={{ width: "auto", maxHeight: "800px" }}
    >
      {/* <div> */}
      <img
        style={{
          objectFit: "cover",
          width: "100%",
          height: "30%",

          maxHeight: "800px",
        }}
        alt="whateverYouWantToSee"
        src={
          imageArray[greatIndex].image || imageArray[greatIndex].imageFile.url
        }
      />
    </div>
  );
}
export default Carousel;
