import React, { useEffect, useState } from "react";
import { Nav, Navbar, Dropdown, DropdownButton, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaUserAlt } from "react-icons/fa";
import logo from "../perceptrondev-high-resolution-logo-transparent.png";
import { useWindowSize } from "@uidotdev/usehooks";
import MsalGroup from "../components/MsalGroup";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { MsalProvider, useMsalAuthentication } from "@azure/msal-react";

import { loginRequest } from "../authConfig";
import { AccountPicker } from "./AccountPicker";
export default function Burger() {
  const [user, setUser] = useState(
    window.sessionStorage.getItem("userInfo") || null
  );
  const size = useWindowSize();
  useEffect(() => {
    setUser(window.sessionStorage.getItem("userInfo") || null);
  }, []);
  const [showProfilePicker, setShowProfilePicker] = useState(false);
  const { instance } = useMsal();

  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  const handleLoginPopup = () => {
    /**
     * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page
     * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
     * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
     */

    instance
      .loginPopup({
        ...loginRequest,
        redirectUri: "/",
      })
      .catch((error) => console.log(error));
  };

  const handleLogoutRedirect = () => {
    instance.logoutRedirect({
      account: instance.getActiveAccount(),
    });
  };

  const handleLogoutPopup = () => {
    instance.logoutPopup({
      mainWindowRedirectUri: "/", // redirects the top level app after logout
      account: instance.getActiveAccount(),
    });
  };

  const handleSwitchAccount = () => {
    setShowProfilePicker(!showProfilePicker);
  };
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="light"
      variant="light"
      style={{ padding: "15px", width: "100%" }}
    >
      {" "}
      <Navbar.Brand href="/" style={{ color: "dark" }}>
        <img
          alt=""
          width="150px
          "
          height="auto"
          className="d-inline-block align-top"
          src={logo}
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/about">About</Nav.Link>
          <Nav.Link href="/contact">Contact</Nav.Link>

          <UnauthenticatedTemplate>
            {size.width < 800 && (
              <div>
                <Nav.Link onClick={handleLoginPopup}>
                  Sign in using Popup
                </Nav.Link>
                <Nav.Link onClick={handleLoginRedirect}>
                  Sign in using Redirect
                </Nav.Link>
              </div>
            )}
            <div className="collapse navbar-collapse justify-content-end">
              <DropdownButton
                variant="secondary"
                className="ml-auto"
                drop="start"
                title="Sign In"
              >
                <Dropdown.Item as="button" onClick={handleLoginPopup}>
                  Sign in using Popup
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={handleLoginRedirect}>
                  Sign in using Redirect
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </UnauthenticatedTemplate>

          <AuthenticatedTemplate>
            {/* <MsalGroup validAzureAdGroupId={`admins`}>  */}
            <Nav.Link href="/BlogForm">New article</Nav.Link>
            {/* </MsalGroup> */}
            {size.width < 800 && (
              <Nav.Link onClick={handleLogoutPopup} className="bg-dark">
                Sign out
              </Nav.Link>
            )}
            <div className="collapse navbar-collapse justify-content-end">
              <DropdownButton
                variant="warning"
                drop="start"
                title={activeAccount ? activeAccount.name : "Unknown"}
              >
                <Dropdown.Item as="button" onClick={handleSwitchAccount}>
                  Switch account
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={handleLogoutPopup}>
                  Sign out using Popup
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={handleLogoutRedirect}>
                  Sign out using Redirect
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <div className="collapse navbar-collapse justify-content-end"></div>
          </UnauthenticatedTemplate>

          <AccountPicker
            show={showProfilePicker}
            handleSwitchAccount={handleSwitchAccount}
          />

          {/* <Nav.Link href="/videos">Videos</Nav.Link>
          <Nav.Link href="/blogform">Submit an article</Nav.Link> */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
