import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

export const Article = ({ article: initialArticle, fetchDataFromAPI }) => {
  const [article, setArticle] = useState(initialArticle);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getArticle = async (articleId) => {
    try {
      const response = await axios.get(
        `https://percms.ukwest.cloudapp.azure.com/BlogArticles/${articleId}`,
        { crossdomain: true }
      );
      setArticle(response.data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  let { articleId } = useParams();

  useEffect(() => {
    if (fetchDataFromAPI) {
      (async () => {
        setLoading(true);
        await getArticle(articleId);
      })();
    } else {
      // Use locally created article data
      setArticle(initialArticle);
      setLoading(false);
    }
  }, [articleId, fetchDataFromAPI, initialArticle]);
  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div>
      <h2>{article.title}</h2>
      <p>Subtitle: {article.subtitle}</p>
      <p>Author: {article.author}</p>
      <p>{new Date(article.date_published).toLocaleDateString()}</p>
      <p>Category: {article.category}</p>
      {article.image_url && (
        <img
          className="m-3"
          src={article.image_url}
          alt="Article "
          style={{ maxWidth: "90%" }}
        />
      )}
      <p>Content: {article.content}</p>
      <p>Meta Description: {article.meta_description}</p>

      <ul>
        <li>Likes: {article.reactions.likes}</li>
        <li>Shares: {article.reactions.shares}</li>
        <li>
          Comments:
          {article.reactions.comment?.length ? (
            <ul>
              {article.reactions.comment.map((comment, index) => (
                <li key={index}>
                  <p>
                    Comment {index + 1}: {comment.content}
                  </p>
                  {/* Add other comment details as needed */}
                </li>
              ))}
            </ul>
          ) : (
            <p>No comments available.</p>
          )}
        </li>
      </ul>
    </div>
  );
};

export default Article;
