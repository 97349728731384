import React, { useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Article from "./Article";
import { BlobServiceClient } from "@azure/storage-blob";

const MockApiUrl = "https://percms.ukwest.cloudapp.azure.com/BlogArticles"; // Replace with your mock API endpoint

const initialFormData = {
  title: "",
  subtitle: "",
  author: "",
  category: "",
  content: "",
  meta_description: "",
  featured: false,
  main: false,
  reactions: {
    likes: 0,
    shares: 0,
    comments: 0,
  },
  image_url: "",
  date_published: new Date(),
};

async function uploadImage(containerName, file) {
  const blobServiceClient = new BlobServiceClient("process.env.BLOB_CLIENT");
  const containerClient = blobServiceClient.getContainerClient(containerName);
  const blobClient = containerClient.getBlobClient(file.name);
  const blockBlobClient = blobClient.getBlockBlobClient();
  const result = await blockBlobClient.uploadBrowserData(file, {
    blockSize: 4 * 1024 * 1024,
    concurrency: 20,
    onProgress: (ev) => console.log(ev),
  });
  console.log(`Upload of file '${file.name}' completed`);
}

const requiredFields = ["title", "subtitle", "author", "category", "content"];

const BlogForm = () => {
  const [formData, setFormData] = useState(initialFormData);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [warningMessage, setWarningMessage] = useState("");
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    uploadImage("images", file);
  };
  const handleImageUpload = () => {
    if (file) {
      uploadImage("images", file);
    }
  };
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));

    validateForm();
  };

  const handleDateChange = (date) => {
    if (!isNaN(date.getTime())) {
      setFormData((prevData) => ({
        ...prevData,
        date_published: date,
      }));
      validateForm();
    } else {
      setWarningMessage("Invalid date format");
    }
  };

  const validateForm = () => {
    const isValid = requiredFields.every(
      (field) =>
        formData[field] !== "" &&
        (field !== "date_published" ||
          !isNaN(new Date(formData[field]).getTime()))
    );

    setButtonDisabled(!isValid);
    setWarningMessage("");
  };

  const handleSendData = () => {
    if (file) {
      handleSubmit(file);
    }
    axios
      .post(MockApiUrl, formData) // Change to POST request for creating a new article
      .then((response) => {
        console.log("API response:", response.data);
        // Handle the response as needed
      })
      .catch((error) => {
        console.error("API error:", error);
        // Handle the error as needed
      });
  };

  return (
    <div className="container mt-5">
      <form>
        {Object.keys(initialFormData).map((key) => {
          if (key === "id") return null; // Exclude id from form
          return (
            <div className="mb-3" key={key}>
              <label className="form-label">
                {key.replace("_", " ")}
                {requiredFields.includes(key) && "*"}
              </label>
              {key === "date_published" ? (
                <DatePicker
                  className="form-control"
                  selected={formData[key]}
                  onChange={(date) => handleDateChange(date)}
                />
              ) : key === "content" ? (
                <textarea
                  className="form-control"
                  name={key}
                  value={formData[key]}
                  onChange={handleInputChange}
                  required
                />
              ) : key === "image_url" ? (
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              ) : key === "featured" || key === "main" ? (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name={key}
                    checked={formData[key]}
                    onChange={handleInputChange}
                  />
                  <label className="form-check-label">{key}</label>
                </div>
              ) : (
                <input
                  className="form-control"
                  type="text"
                  name={key}
                  value={formData[key]}
                  onChange={handleInputChange}
                  required={requiredFields.includes(key)}
                />
              )}
            </div>
          );
        })}
        {warningMessage && (
          <div className="alert alert-warning" role="alert">
            {warningMessage}
          </div>
        )}
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleSendData}
          disabled={isButtonDisabled}
        >
          Send Data
        </button>
      </form>
      <Article article={formData} />
    </div>
  );
};

export default BlogForm;
