import React from "react";
import { useNavigate } from "react-router-dom";

const MyCard = (props) => {
  const { title, content, image_url, subtitle, id } = props.article;
  const navigate = useNavigate();

  // Function to handle card click
  const handleCardClick = () => {
    navigate(`/article/${id}`); // Navigate to the corresponding article
  };

  // Function to handle button click
  const handleButtonClick = (e) => {
    e.stopPropagation(); // Prevent the event from propagating to the card click handler
    navigate(`/article/${id}`); // Handling button click the same as card click for this example
  };

  // Function to strip markdown (as detailed in the previous example)
  const stripMarkdown = (markdown) => {
    return markdown
      .replace(/[#*]+/g, "") // Other replacements as necessary
      .trim();
  };

  // Function to truncate content (as detailed in the previous example)
  const truncateContent = (text, maxLength = 100) => {
    if (!text) return "";
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div className="col-md-12 col-sm-6 mb-4">
      <div className="card" onClick={handleCardClick}>
        <img
          className="card-img-top img-fluid" // assuming you're using Bootstrap
          src={image_url}
          alt="Article"
          style={{ maxWidth: "100%", height: "auto" }} // makes the image responsive
        />
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <p className="card-text">{truncateContent(stripMarkdown(content))}</p>
          <p className="card-text">{subtitle}</p>
          <button className="btn btn-primary" onClick={handleButtonClick}>
            Read More
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyCard;
