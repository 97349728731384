import React from "react";
import engineers from "../img/311940531_3400905356808959_3351801815978223665_n.jpg";
import { Image } from "react-bootstrap";
const About = () => {
  return (
    <div className="container row pt-5">
      <div className=" col-lg-6 col-sm-12  text-end pb-3">
        <em>
          {" "}
          "AI is fueled by the need for data scientists and mathematical science
          professionals. These occupations are expected to grow 33% from 2020 to
          2030 in the United States.
        </em>
        <br />
        <br />
        <br />{" "}
        <strong>
          <em>US Bureau of Labor Statistics"</em>
        </strong>{" "}
        <br />
        <br />
        Dear reader. I want to explain the reason behind the creation of this
        website. There was an inspirational moment after completing AI
        fundamentals. A feeling of urgency to take the AI side and try to make
        use of it rather than be among those affected job holders we all hear
        about. I mostly hold different AI certifications and no Machine Learning
        degree but work in tech and actively participate in the educational
        process leading a team studying AI for work and running a prompt
        engineering production project.
        <br /> What problem this website tries to solve: <br />
        <br />
        1). To simplify learning so that it will attract more people.
        <br />
        <br /> 2). Linguists for example gain value with the introduction of
        prompting and cross domain collaboration opens new perspectives. There
        is still a gap between people using ChatGPT API and normal chat users.
        At the same time on linkedin I noticed some interesting, more
        philosophical discussions on AI from artists and educators researching
        topics of AI developing “new senses”. which could be useful for
        developers. My aim is to keep this as light and fun as possible to
        create a very diverse community of people interested in learning AI or
        getting answers to their questions or suggesting their own ideas..
        Perceptron.dev is looking to attract a team as diverse as possible and
        will be glad to hear from authors.
        <br />
        <br />
      </div>
      <div className="col-lg-6 col-sm-12" style={{ maxHeight: "150px" }}>
        <Image
          fluid
          src={engineers}
          alt="engineers engineering"
          style={{ justifySelf: "right" }}
          max-width={"50vw"}
        />
      </div>{" "}
    </div>
  );
};

export default About;
