import React from "react";

const Videos = () => {
  return (
    <div className="container row">
      <div className="col-lg-12">
        <iframe
          title="omygosh"
          src="https://www.loom.com/embed/888c1613487b4eeda8f1432de5aa1465?sid=91f5cc6c-3acf-46f1-942e-58556eb80e2c"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          style={{
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            marginTop: "25px",
          }}
        ></iframe>

        <iframe
          title="omygosh2"
          src="https://www.loom.com/embed/df2c6476b21a45b0b57f4398135559cf?sid=c939363c-3960-4aa3-b4ad-cc38b12f3a37"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          style={{
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            marginTop: "25px",
          }}
        ></iframe>
      </div>
    </div>
  );
};

export default Videos;
